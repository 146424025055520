<template>
  <v-parallax
    :src="require('@/assets/mezcal/cocteleria_6.jpeg')"
  >
  </v-parallax>
</template>

<script>
  export default {
    name: 'Parallax',
  }
</script>

<style scoped>
  .parallax-desc {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
  }
</style>
